import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as rootState from '../../store';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {UserFormError} from '../../store/form/form.actions';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit, OnDestroy {
  @ViewChild('userForm') userForm: NgForm;
  @Input() user: any;
  @Input() isLoading: boolean;
  @Output() onSave: EventEmitter<{user: any, userprofile: any}> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  formError$: Observable<any>;
  countryCodes: Array<any>;
  countryCode: string;

  private _formSubscription: Subscription;

  constructor(private _store: Store<rootState.IAppState>) {}

  ngOnInit(): void {
    this.formError$ = this._store.pipe(select(rootState.getUserFormError));

    this.countryCodes = [
      { key: '+47 (Norge)', value: '+47' },
      { key: '+46 (Sverige)', value: '+46' },
      { key: '+45 (Danmark)', value: '+45' },
      { key: '+44 (UK)', value: '+44' },
      { key: '+1 (USA)', value: '+1' },
      { key: '+354 (Island)', value: '+354' },
      { key: '+298 (Færøyene)', value: '+298' },
    ];

    this.countryCode = this.countryCodes[0].value;

    if (this.user.userprofile.cell_phone_number && this.user.userprofile.cell_phone_number.includes('+')) {
      this.countryCode = this.user.userprofile.cell_phone_number.substr(0, 3);
      this.user.userprofile.cell_phone_number = this.user.userprofile.cell_phone_number.substr(3, 10);
    }

    this._formSubscription = this.userForm.valueChanges
      .subscribe(_ => {
        setTimeout(() => {
          if (this.user.is_superuser && this.user.is_service_receiver) {
            this._store.dispatch(new UserFormError({type: 'editUser', error: 'A user cannot be a Service Receiver and a SuperUser.'}));
          } else if (this.user.is_employee && this.user.is_service_receiver) {
            this._store.dispatch(new UserFormError({type: 'editUser', error: 'A user cannot be an Employee and a Service Receiver.'}));
          } else {
            this._store.dispatch(new UserFormError({type: 'editUser', error: null}));
          }
        }, 100);
      });
  }

  save(): void {
    const user = Object.assign({}, {
      id: this.user.id,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      is_superuser: this.user.is_superuser,
      is_service_receiver: this.user.is_service_receiver,
      is_employee: this.user.is_employee,
      is_relative: this.user.is_relative,
      using_jodabook: this.user.using_jodabook,
      is_active: this.user.is_active,
    });

    let userprofile = Object.assign({}, this.user.userprofile);

    if (!user.is_service_receiver) {
      userprofile = Object.assign({}, {
        cell_phone_number: (!this.user.userprofile.cell_phone_number.includes('+'))
          ? this.countryCode + this.user.userprofile.cell_phone_number : this.user.userprofile.cell_phone_number
      });
    }

    this.onSave.emit({user: user, userprofile: userprofile});
  }

  ngOnDestroy(): void {
    if (this._formSubscription) {
      this._formSubscription.unsubscribe();
    }
  }
}
