import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {UserFormComponent} from './user-form.component';

@NgModule({
  declarations: [
    UserFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    UserFormComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class UserFormModule {}
